import React, { useEffect, useRef } from 'react';
import useMatrixStream from './useMatrixStream';
import './MatrixStream.css';

const MatrixColumn: React.FC<{ rows: number }> = ({ rows }) => {
    const streamContainer = useMatrixStream(rows);

    return <div className="column" ref={streamContainer}></div>;
};

const MatrixStreamComponent: React.FC = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const columns = 20;
    const rows = 30;

    return (
        <div id="container" ref={containerRef} className="matrix-container">
            {Array.from({ length: columns }).map((_, index) => (
                <MatrixColumn key={index} rows={rows} />
            ))}
        </div>
    );
};

export default MatrixStreamComponent;
