import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

let firebaseConfig = {
    apiKey: "AIzaSyCsr-M9YAzhY2WQegHk3L6vfRLZLaIZrHY",
    authDomain: "mell-4b08a.firebaseapp.com",
    projectId: "mell-4b08a",
    storageBucket: "mell-4b08a.appspot.com",
    messagingSenderId: "195923202682",
    appId: "1:195923202682:web:2cc0f596a7c420ea7921c3"
};

// if (window.location.hostname === "localhost") {
//     firebaseConfig = {
//         apiKey: "AIzaSyBelHGPdMMzye32J2wXL_srIwNAbEOQDRM",
//         authDomain: "upvote-88f08.firebaseapp.com",
//         projectId: "upvote-88f08",
//         storageBucket: "upvote-88f08.appspot.com",
//         messagingSenderId: "960446388881",
//         appId: "1:960446388881:web:56090d11c6d92b511afd30",
//         measurementId: "G-5S00R8XKR0"
//     };
// }

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const functions = getFunctions(app);

// if (window.location.hostname === "localhost") {
//     connectFunctionsEmulator(functions, "localhost", 8894);
// }

export { db, storage, auth, app, functions };
