

// @ts-ignore
export  const calculateCostAndProfit = (initialCost: number, initialProfit: number, level: number) => {
    if(level <= 1) {
        return {
            cost:  Math.round(initialCost),
            profit:  Math.round(initialProfit)
        };
    }
    const cost = initialCost * Math.pow(1.5, level);
    const profit = initialProfit * Math.pow(1.5, level)

    return {
        cost:  Math.round(cost),
        profit:  Math.round(profit)
    };
}
