import React, {useState, useEffect, FC, useRef} from 'react';
import {db, functions} from '../../firebase/init';
import {collection, query, getDocs} from 'firebase/firestore';
import 'react-toastify/dist/ReactToastify.css';
import './Index.css';
import {formatSpace, nFormatter} from "../../utils/formatsNum";
import {toast} from "react-toastify";
import {httpsCallable} from "firebase/functions";
import {ImpactOccurredFunction, useHapticFeedback, useInitData} from "@vkruglikov/react-telegram-web-app";
import {calculateCostAndProfit} from "../../utils/utils";

const buyItem = httpsCallable(functions, 'buyItem');


interface EarnProps {
    close: () => void;
    save: (points: number, earnPerHour: number) => void;

    totalCount: string
}

interface Items {
    name: string;
    bonus: number;
    preview: string,
    video: string,
    id: number,
    initPrice: number,
    priceLevel: number,
    profitOld: number,
    description: string,
    currentLevel: number,
    bonusLevel: number,
    upPrice: number
}

const EarnPage: FC<EarnProps> = ({close, totalCount, save}) => {
    const [impactOccurred] = useHapticFeedback();
    const handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            setSelectItem(null)
        }
    };

    const [styleHapticHeavy] =
        useState<Parameters<ImpactOccurredFunction>[0]>('heavy');
    const [styleHapticSoft] =
        useState<Parameters<ImpactOccurredFunction>[0]>('soft');

    const [InitDataUnsafe, InitData] = useInitData();

    const [items, setItems] = useState<Items[]>([]);
    const [videoPlay, setVideoPlay] = useState('');
    const [videoIsPlay, setVideoIsPlay] = useState(false);


    const [selectItem, setSelectItem] = useState<Items | null>(null);
    const [loader, setLoader] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const [isTransitioning, setIsTransitioning] = useState(false);
    const notify = (title: string) => toast(`Ура! 🎉🥳 Теперь у тебя есть ${title}!`);
    const notifyUp = (title: string) => toast(`Ура! 🎉🥳 У ${title} повышен уровень!`);

    document.body.style.overflow = 'hidden';
    const fetchItems = async () => {
        const q = query(collection(db, 'items'));
        const querySnapshot = await getDocs(q);

        const userItemsRef = collection(db, 'users', String(InitDataUnsafe?.user?.id || '308482295'), 'items');

        const qU = query(userItemsRef);
        const querySnapshotUser = await getDocs(qU);
        const userItems = querySnapshotUser.docs.map(doc => ({...doc.data(), id: doc.id}));

        const result: Items[] = [];

        querySnapshot.forEach((doc) => {

            const userItem = userItems.find(({id}) => doc.id === id)

            const d = doc.data();
            // @ts-ignore
            const currentLevel = userItem ? userItem?.level + 1 : 0
            // @ts-ignore
            const old = calculateCostAndProfit(d?.initPrice, d?.bonus, currentLevel)

            const {cost, profit} = calculateCostAndProfit(d?.initPrice, d?.bonus, currentLevel + 1)
            const data = calculateCostAndProfit(d?.initPrice, d?.bonus, currentLevel)

            result.push({
                name: d?.name,
                bonus: profit,
                preview: d?.preview,
                video: d?.video,
                profitOld: old?.profit,
                id: d?.id,
                description: d?.description,
                initPrice: cost,
                upPrice: profit - data.profit,
                priceLevel: d?.priceLevel,
                bonusLevel: d?.bonusLevel,
                currentLevel
            });
        });

        setItems(result);
    };


    useEffect(() => {
        setIsTransitioning(true)
        fetchItems();
    }, []);

    const confirmHandler = async (item: Items) => {
        if(loader) {
            return
        }
        setLoader(true)
        impactOccurred(styleHapticHeavy)
        let response;
        if (window.location.hostname === "localhost") {
            const data = await buyItem({
                initData: 'user=%7B%22id%22%3A308482295%2C%22first_name%22%3A%22Michael%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22MichaelLobanov%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=-1594534907276748447&chat_type=sender&start_param=rp_5672917460&auth_date=1718135065&hash=7e229ad440e463849f28d6713ce14bde3f934d16b87aee506c7853414924552a',
                itemId: item.id
            })

            response = data.data
        } else {
            const data = await buyItem({
                initData: InitData,
                itemId: item.id
            })

            response = data.data
        }

        // @ts-ignore
        if (response?.success) {
            setVideoPlay(item.video)

            setTimeout(() => {
                setVideoIsPlay(true)
                if (videoRef.current) {
                    try {
                        videoRef.current.play();
                    } catch (e) {}
                }
            }, 500);

            if (item.currentLevel < 1) {
                notify(item.name)

            } else {
                notifyUp(item.name)
            }

            fetchItems();
            setSelectItem(null)
            save(item.initPrice, item.upPrice || item.bonus)

        }

        setLoader(false)

    }


    return (
        <div className={`earn-page ${isTransitioning ? 'show' : ''}`}>
            <div className='earn-container'>
                <div className="coin-line">
                    <img className="coin-img" src='/coin.png' alt="coin"/>
                    <h1>{formatSpace(String(totalCount))}</h1>
                </div>
                <div className={'notify'}>
                    Если вы офлайн предметы приносят прибыль в течении 3-х часов
                </div>
                <div className="items-container">

                    {items.map((item, index) => (
                        <div key={index} className={ item.currentLevel >= 15 ? 'max-level item' : Number(totalCount)  > Number(item.initPrice)  ? 'item' : 'item disabled-item'} onClick={() => {
                            impactOccurred(styleHapticSoft)
                            setSelectItem(item)
                        }}>
                            <div className={'item-img'}>
                                <img src={item.preview}/>
                            </div>
                            <div className={'item-info'}>
                                <span>{item.name}</span>
                                {item.currentLevel >= 12 ? <div className="max-level-container"><strong>Достигнут максимальный уровень</strong></div> : <div className="item-price"><span>Прибыль после апа:</span>
                                    <strong>+{nFormatter(item.bonus)}</strong>
                                </div> }
                                <div className="item-price"><span>Приносит в час сейчас:</span>
                                    <strong>+{nFormatter(item.profitOld)}</strong>
                                </div>
                                <strong className="item-lvl">LVL {item.currentLevel}</strong>
                            </div>
                            <div className="final-price-container">
                                <span>{nFormatter(item.initPrice)}</span> <img src={'/coin.png'}
                                                                               className={'item-coin'}/>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="footer-earn">
                <a className="close" onClick={close}>Закрыть</a>
            </div>
            {videoPlay && (
                <div className={"video-play"} onClick={() => setVideoPlay('')}>
                    <video  className={"animate__animated animate__zoomIn"} src={videoPlay}
                           ref={videoRef}
                           onEnded={() => setVideoPlay('')}
                           playsInline={true} autoPlay={true}></video>
                </div>
            )}
            {selectItem &&
                <div className="confirm-modal-container" onClick={handleBackgroundClick}>
                    <div className="confirm-modal animate__animated animate__bounceInUp">
                        <div>
                            <div className="confirm-content">
                                <img src={selectItem.preview} className={'confirm-image'}/>
                                <h3>{selectItem.name}</h3>
                                <span>{selectItem.description}</span>
                                <div>
                                    <div className="item-price"><span>Прибыль будет увеличена:</span>
                                        <strong>+{nFormatter(selectItem.upPrice || selectItem.bonus)}</strong>
                                    </div>
                                </div>
                            </div>

                            <div className={'price-confirm'}>
                                <span>{nFormatter(selectItem.initPrice)}</span> <img src={'/coin.png'}
                                                                                     className={'item-coin'}/>
                            </div>
                            <div className="button-link">
                                <div className="Button-next full" onClick={() => confirmHandler(selectItem)}>{loader ? 'Идет покупка ...' : 'Получить' }</div>
                            </div>
                            <div>
                                <a className="close" onClick={() => setSelectItem(null)}>Закрыть</a>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default EarnPage;
