const nacl = require('tweetnacl');
const naclUtil = require('tweetnacl-util');

export function signData(data: any, serverSecretKey: any) {
    const secretKeyUint8 = serverSecretKey;
    const timestamp = Date.now().toString();
    const dataWithTimestamp = `${data}||${timestamp}`;
    const dataUint8 = naclUtil.decodeUTF8(dataWithTimestamp);
    const signature = nacl.sign.detached(dataUint8, secretKeyUint8);
    return {
        b1: naclUtil.encodeBase64(dataUint8),
        hj: naclUtil.encodeBase64(signature)
    };
}
