import React, {FC, useEffect, useRef, useState} from "react";
import './Index.css'
import MatrixStream from "./MatrixStream";
import AnimatedInput from "./AnimatedInput";
import {getFunctions, httpsCallable} from "firebase/functions";
import {db, app} from "../../firebase/init";
import {useInitData} from "@vkruglikov/react-telegram-web-app";
import {toast} from "react-toastify";
import {nFormatter} from "../../utils/formatsNum";
import success = toast.success;


interface WorkProps {
    dancekey: number;
    close: () => void;
    onSuccess: (amount: number) => void;
}

const functions = getFunctions(app, 'us-central1')
const activateCode = httpsCallable(functions, 'activateCode');


const CipherPage: FC<WorkProps> = ({close, dancekey,onSuccess}) => {
    const [isTransitioning, setIsTransitioning] = useState(false);
    const audioRef = useRef<HTMLVideoElement>(null);
    const [InitDataUnsafe, InitData] = useInitData();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setIsTransitioning(true)
    });

    document.body.style.overflow = 'hidden';

    const handleChange = async (text: string) => {
        setLoading(true)
        let dataInitData = InitData
        if (window.location.hostname === "localhost") {
        }
        // @ts-ignore
        const {data} = await activateCode({code: text, initData: dataInitData});
        // @ts-ignore
        if (data?.success) {
            setLoading(false)
            // @ts-ignore
            toast.success('Вам зачислено ' +  nFormatter(data?.amount) + ' на баланс');
            // @ts-ignore
            onSuccess(Number(data?.amount))
        } else {
            setLoading(false)
            toast.error('Вы ввели какую-то шляпу');


        }
    }

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    })

    return (
        <div className={`cipher ${isTransitioning ? 'show' : ''}`}>
            <audio
                autoPlay={true}
                src={'/audio.mp3'}
                loop
                ref={audioRef}
            ></audio>
           <AnimatedInput loading={loading} inputHandler={handleChange}/>
            <MatrixStream/>
            <div className="footer-friends">
                <a className="close" onClick={close}>Закрыть терминал</a>
            </div>
        </div>
    )
}

export default CipherPage
