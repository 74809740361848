import React, { useState, useEffect, FC } from 'react';
import { db } from '../../firebase/init';
import { collection, query, where, getDocs, DocumentData } from 'firebase/firestore';
import 'react-toastify/dist/ReactToastify.css';
import './Index.css';
import { useInitData, useWebApp } from "@vkruglikov/react-telegram-web-app";

interface FriendProps {
    close: () => void;
}

interface Referral {
    username: string;
    refPoint: number;
    firstName: string;
}

const FriendsPage: FC<FriendProps> = ({ close }) => {
    const [referralLink, setReferralLink] = useState('');
    const [referrals, setReferrals] = useState<Referral[]>([]);
    const WebApp = useWebApp();
    const [InitDataUnsafe, InitData] = useInitData();

    const [isTransitioning, setIsTransitioning] = useState(false);

    document.body.style.overflow = 'hidden';

    useEffect(() => {
        setIsTransitioning(true);
        const generateReferralLink = () => {
            const link = `https://t.me/mellstroy_kombat_bot/start?startapp=rp_${InitDataUnsafe?.user?.id}`;
            setReferralLink(link);
        };
        generateReferralLink();
    }, [InitDataUnsafe]);

    useEffect(() => {
        const fetchReferrals = async () => {
            const userId = String(InitDataUnsafe?.user?.id || '308482295');
            const q = query(collection(db, 'users'), where('refferenceId', '==', userId));
            const querySnapshot = await getDocs(q);

            const result: Referral[] = [];

            querySnapshot.forEach((doc) => {
                const d = doc.data();
                result.push({
                    username: d?.username,
                    refPoint: d?.refPoint,
                    firstName: d?.first_name
                });
            });

            setReferrals(result);
        };
        fetchReferrals();
    }, [InitDataUnsafe]);

    const text = `Тапай со мной! Kликер с Мелстроем уже в телеграм! Первые три дня увеличеный бонус за приведенного друга!%0A%F0%9F%8E%81+6k монет в качестве первого подарка%0A%F0%9F%8E%81+30k монет, если у тебя есть Telegram Premium`;

    return (
        <div className={`friends-page ${isTransitioning ? 'show' : ''}`}>
            <div className='friends-container'>
                <h1>Пригласите друзей</h1>
                <p>Пригласи своего друга или рандомного чела и получи бонус</p>
                <div>
                    <div className="invite-friend">
                        <span>Пригласите друга</span>
                        <div className="money">
                            <strong>+6 000</strong>
                            <img src="/coin.png" alt="coin"/>
                        </div>
                    </div>
                    <div className="invite-friend">
                        <span>Пригласите друга с Telegram Premium</span>
                        <div className="money">
                            <strong>+30 000</strong>
                            <img src="/coin.png" alt="coin"/>
                        </div>
                    </div>
                </div>
                <h2>Мои рефералы ({referrals.length})</h2>
                <div className="referral-container">
                    {referrals.map((referral, index) => (
                        <div className={'referral-li'} key={index}>
                            <span>{referral.username || referral.firstName}</span>
                            <div className="money">
                                <strong>+{referral.refPoint}</strong>
                                <img src="/coin.png" alt="coin"/>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="footer-friends">
                <div className="share-link">
                    <div
                        className="tg-button"
                        onClick={() => WebApp.openTelegramLink('https://t.me/share/url?url=' + referralLink + '&text=' + text)}>
                        Пригласить друга
                    </div>
                    <div className="copy-link" onClick={() => navigator.clipboard.writeText(referralLink)}></div>
                </div>
                <a className="close" onClick={close}>Закрыть</a>
            </div>
        </div>
    );
};

export default FriendsPage;
