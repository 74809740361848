import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useSwipeable} from 'react-swipeable';
import './Onboarding.css'
import {useInitData, useWebApp} from "@vkruglikov/react-telegram-web-app";
import {db, functions} from "../../firebase/init";
import {doc, getDoc, updateDoc} from "firebase/firestore";
import {httpsCallable} from "firebase/functions";
const registerUser = httpsCallable(functions, 'registerUser');


interface OnboardingProps {
    navigate: (st: boolean) => void;
}
const MainOnboarding: FC<OnboardingProps> =  ({navigate}) => {
    const [index, setIndex] = useState(0);
    const [loader, setLoader] = useState(true);
    const [nextStep, setNextStep] = useState(false);
    const [InitDataUnsafe, InitData] = useInitData();
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef_0 = useRef<HTMLVideoElement>(null);


    const updateOnboarding = async () => {
        const docRef = doc(db, 'users', String(InitDataUnsafe?.user?.id || '308482295'));
        await updateDoc(docRef, {
            onboarding: true
        });
        navigate(true);
    }


    useMemo(async () => {
        const docRef = doc(db, 'users', String(InitDataUnsafe?.user?.id || '308482295'));
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            let refferenceId = null
            if (InitDataUnsafe && InitDataUnsafe.start_param && InitDataUnsafe?.start_param?.includes('rp_')) {
                refferenceId = InitDataUnsafe?.start_param?.replace('rp_', '')
            }
            if (window.location.hostname === "localhost") {

            } else {
                await registerUser({
                    initData: InitData,
                    refferenceId: refferenceId
                })
            }
        } else {
            if(docSnap.data().onboarding) {
                navigate(true);
            }
        }

        setNextStep(true)
    }, [])

    const [isTransitioning, setIsTransitioning] = useState(false);


    const pages = [
        {
            id: 1,
            content: 'Кликай чем хочешь и чем можешь',
            title: 'Кликай на экран и зарабатывай монеты',
            source: '/videos/first.mp4'
        },
        {
            id: 2,
            title: 'Приглашай друзей и получайте бонусы вместе',
            content: 'Монеты получают все, ты и ваш друг',
            source: '/videos/second.mp4'
        },
        {
            id: 3,
            title: 'Жди листинга mellstroy coin на биржах',
            content: 'Не забудь пригласить своих друзей',
            source: '/videos/thrid.mp4'
        },
        {
            id: 4,
            title: 'Используй монеты для получения вознаграждения при листинге токенов',
            content: '',
            source: '/videos/four.mp4'
        }
    ];

    const handleUserInteraction = () => {
        if (videoRef_0.current && !isPlaying) {
            videoRef_0.current.play();
            videoRef_0.current.muted = false
            setIsPlaying(true);
            document.removeEventListener('click', handleUserInteraction);
            document.removeEventListener('keydown', handleUserInteraction);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleUserInteraction);
        document.addEventListener('keydown', handleUserInteraction);

        return () => {
            document.removeEventListener('click', handleUserInteraction);
            document.removeEventListener('keydown', handleUserInteraction);
        };
    }, [isPlaying]);

    const handlers = useSwipeable({
        onSwipedLeft: () => {

            setIsTransitioning(true);
            setTimeout(() => {
                setIndex((index + 1) % pages.length)
                setIsTransitioning(false);
            }, 300);
        },
        onSwipedRight: () => {
            setIsTransitioning(true);
            setTimeout(() => {
                setIndex((index - 1 + pages.length) % pages.length)
                setIsTransitioning(false);
            }, 300);

        },
        // @ts-ignore
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    useEffect(() => {
        if (videoRef_0.current && isPlaying) {
            videoRef_0.current.play();
        }
    }, [index, isPlaying]);


    // useEffect(() => {
    //     if (videoRef_0.current) {
    //         const videoElement = videoRef_0.current;
    //
    //         setTimeout(() => {
    //             // setLoader(false)
    //             setNextStep(true)
    //         }, 1000)
    //     }
    // }, []);

    // @ts-ignore
    return (
        <div>
            {
                loader && <div className="preload-container">


                    {nextStep ? <div className=" buttons-first" onClick={() => setLoader(false)}>
                        <img className="coin" src="/coin.png"/>
                        <span className="blink">Тапни по монетке</span>
                    </div> :   <img className="loader-spin" src="/loader-one.png"/>}
                </div>
            }
            <div className="onboarding-container" {...handlers}>
                <div className="onboarding-content">
                    <h1>{pages[index].title}</h1>
                    <span>{pages[index].content}</span>
                    <div className={'shadow'}>
                        <video
                            className={`videos-content ${isTransitioning ? 'fade' : ''}`}
                            preload="auto"
                            style={{'width': '260px', height: '260px'}}
                            playsInline={true}
                            onClick={handleUserInteraction}
                            onKeyDown={handleUserInteraction}
                            ref={videoRef_0}
                            src={pages[index].source}
                            loop
                            muted={true}></video>
                    </div>
                </div>
                <div className="onboarding-navigation">
                    {index < 3 ? <>
                        <div className="Button-next hide">Далее</div>
                        <div className="buttons">
                            {pages.map((_, i) => (
                                <span
                                    key={i}
                                    className={`dot ${i === index ? 'active' : ''}`}
                                    onClick={() => {
                                        setIsTransitioning(true);
                                        setTimeout(() => {
                                            setIndex(i)
                                            setIsTransitioning(false);
                                        }, 300)
                                    }}
                                ></span>
                            ))}
                        </div>

                        <div className="Button-next" onClick={() => {
                            setIsTransitioning(true);
                            setTimeout(() => {
                                setIndex(index + 1)
                                setIsTransitioning(false);

                            }, 300)
                        }}>Далее
                        </div>
                    </> : (
                        <div onClick={updateOnboarding} className="button-link">
                            <div className="Button-next full">Играть</div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default MainOnboarding;
