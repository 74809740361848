/* eslint-disable import/first */


import React, {useEffect, useState} from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import './index.css'
import 'animate.css/animate.min.css';
import MainOnboarding from "./pages/onboarding/MainOnboarding";
import NotcoinPage from "./pages/notcoin";

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "@wethegit/react-autoplay-video/style.css"
import {useExpand, useInitData, useThemeParams, useWebApp, WebAppProvider} from "@vkruglikov/react-telegram-web-app";
import {TonConnectUIProvider} from "@tonconnect/ui-react";
// import eruda from 'eruda'
//
// eruda.init()

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)


const App = () => {
    const [smoothButtonsTransition, setSmoothButtonsTransition] = useState(false);
    const [isExpanded, expand] = useExpand();
    const [InitDataUnsafe, InitData] = useInitData();

    const webApp = useWebApp();
    const [isMobile, setIsMobile] = useState('');
    const [isFarm, setIsFarm] = useState(false)

    try {
        expand()
        webApp.setHeaderColor('#000')
        webApp.setBackgroundColor('#000')
    } catch (e) {}
    useEffect(() => {
        try {
            expand()
            webApp.setHeaderColor('#000')
            webApp.setBackgroundColor('#000')
        } catch (e) {}
        setIsMobile(webApp.platform);
    }, []);

    // if (String(InitDataUnsafe?.user?.id) !== String(875170006) && String(InitDataUnsafe?.user?.id) !== String(6043978489)) {
    //     return (
    //         <div className="desktop-app">
    //             <h3>Ведутся технические работы</h3>
    //
    //         </div>
    //     )
    // }

    if (!['ios', 'android'].includes(isMobile)) {
        return (
            <div className="desktop-app">
                <h3>Играй с телефона</h3>
                <img src="/qr.jpg" className="desktop-app-qr"/>
                <a href="https://t.me/mellstroy_kombat_bot">@mellstroy_kombat_bot</a>
            </div>
        )
    } else {
        return (
            <WebAppProvider options={{smoothButtonsTransition}}>
                <TonConnectUIProvider manifestUrl="https://mellstroykombat.xyz/manifest.json">

                <main>
                        {!isFarm ? <MainOnboarding navigate={() => setIsFarm(true)}/> :  <NotcoinPage/>}
                        <ToastContainer theme="dark"/>
                    </main>
                </TonConnectUIProvider>
            </WebAppProvider>
        )
    }
};

root.render(<App/>);
