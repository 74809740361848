import React, {FC, useEffect, useRef, useState} from "react";
import './Index.css'
import {TonConnectButton} from "@tonconnect/ui-react";
import Lottie from 'react-lottie-player'
import lottieJson from './wallet.json'

interface WorkProps {
    close: () => void;
}

const WalletPage: FC<WorkProps> = ({close}) => {
    const [isTransitioning, setIsTransitioning] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        setIsTransitioning(true)
    });

    document.body.style.overflow = 'hidden';

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    })

    return (
        <div  className={`wallet-page ${isTransitioning ? 'show' : ''}`}>
            <div className="wallet-container">
                <Lottie
                    loop
                    animationData={lottieJson}
                    play
                    style={{ width: 200, height: 200 }}
                />
            <TonConnectButton  className="ton-connection-button-class" />
            </div>
            <div className="footer-friends">
                <a className="close" onClick={close}>Закрыть</a>
            </div>
        </div>
    )
}

export default WalletPage
