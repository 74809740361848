import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Notcoin} from "./Notcoin";
import './NotcoinIndex.css'
import {toast} from 'react-toastify';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {db, functions} from "../../firebase/init";
import {
    ImpactOccurredFunction,
    useHapticFeedback, useInitData,
} from '@vkruglikov/react-telegram-web-app';
import {getUserLevel} from "../../utils/levels";
import WorkProgress from "./workProgress";
import FriendsContainer from "./../friends/friends";
import {formatSpace, nFormatter} from "../../utils/formatsNum";
import {httpsCallable} from 'firebase/functions';
import _ from "lodash";
import EarnPage from "../earn/earn";
import CryptoJS from 'crypto-js';
import CipherPage from "../cipher/cipher";
import WinnersPage from "../winners/winners";
import nacl from 'tweetnacl'
import naclUtil from 'tweetnacl-util';
// import eruda from 'eruda'
import {signData} from "../../utils/crpt";
import WalletPage from "../wallet/wallet";

// eruda.init()
dayjs.extend(duration);

const syncTaps = httpsCallable(functions, 'syncPointsV3');
const getUserProfile = httpsCallable(functions, 'getUserProfile');

let saveDev = {
    pointSave: 0,
    lock: false,
}

const serverKeyPair = nacl.sign.keyPair();
const serverPublicKey = naclUtil.encodeBase64(serverKeyPair.publicKey);
const serverSecretKey = serverKeyPair.secretKey
const datetimeNow = dayjs()


// @ts-ignore
const syncTapsDebounce = _.debounce(async (points, initData, energy) => {
    let totalPoints = Number(points) - Number(saveDev.pointSave);
    const datetimeCurrent = dayjs()
    const differenceInSeconds = datetimeCurrent.diff(datetimeNow, 'second')

    if (totalPoints > 0) {
        const data = JSON.stringify({points: totalPoints, energy, nonce: differenceInSeconds})
        await syncTaps({initData: initData, hash: signData(data, serverSecretKey)})
    }

    saveDev.pointSave = points
}, 500)


const levels = [
    {name: 'Плаки плаки', points: 0},
    {name: 'Лысый', points: 5000},
    {name: 'Мопед', points: 25000},
    {name: 'Дудец', points: 100000},
    {name: 'Драганмани', points: 1000000},
    {name: 'Тете-тете', points: 2000000},
    {name: 'Танцор', points: 10000000},
    {name: 'Нормалдаки', points: 50000000},
    {name: 'Бизнесмен', points: 100000000},
    {name: 'Недосягаемый', points: 10000000000000},
];



const NotcoinPage = () => {
    const [impactOccurred] = useHapticFeedback();
    const [styleHaptic] =
        useState<Parameters<ImpactOccurredFunction>[0]>('light');
    const [styleHapticSoft] =
        useState<Parameters<ImpactOccurredFunction>[0]>('soft');
    const [totalCount, setTotalCount] = useState(0);
    const [energy, setEnergy] = useState(1000);
    const [work, setWork] = useState(0);
    const [InitDataUnsafe, InitData] = useInitData();
    const [loader, setLoader] = useState(false);
    const [bonusPerHour, setBonusPerHour] = useState(0);
    const [pointsEarn, setPointsEarn] = useState(0);

    const [level, setLevel] = useState(0);
    const [levelEnergy, setLevelEnergy] = useState(1000);
    const [opacity, setOpacity] = useState(1);
    const [nextLevelPoint, setNextLevelPoint] = useState(1000);
    const [progress, setProgress] = useState(0);
    const notify = (levelName: string) => toast(`Открыт новый уровень: ${levelName}!`);
    const [videoVisible, setVideoVisible] = useState(false);
    const [videoVisibleRed, setVideoVisibleRed] = useState(false);
    const [pointsInItems, setPointsInItems] = useState(0);

    const videoRef = useRef<HTMLVideoElement>(null);


    useEffect(() => {

        const fetchData = async () => {
            if (saveDev.lock) {
                return
            }

            setLoader(true)
            saveDev.lock = true
            // @ts-ignore
            let response: UserProfileResponse;
            if (window.location.hostname === "localhost") {
                const data = await getUserProfile({
                })


                response = data.data
            } else {

                const data = await getUserProfile({
                    initData: InitData,
                    // @ts-ignore
                    hash: InitDataUnsafe.hash,
                    // @ts-ignore
                    kl: CryptoJS.AES.encrypt(serverPublicKey, String(InitDataUnsafe.hash)).toString()
                })

                response = data.data
            }


            if (response?.success) {
                const points = response.profile.points;
                setTotalCount(points);
                setBonusPerHour(response.profile.bonusPerHour || 0)

                setPointsInItems(response.profile.pointsInItems || 0)
                saveDev.pointSave = Number(String(points));
                const index = getUserLevel(points, levels);

                try {
                    const energy = (500 * (index + 1));
                    const date2 = dayjs(response.profile.lastUpdatedTapPoints * 1000);
                    const date1 = dayjs(response.serverDate * 1000);

                    const diffInSeconds = date1.diff(date2, 'second');

                    if (diffInSeconds > energy) {
                        setEnergy(energy)
                    } else {
                        const energyCalc = response.profile.energy + (diffInSeconds * 2)


                        if (energyCalc > energy) {
                            setEnergy(energyCalc)
                        } else {
                            setEnergy(isNaN(energyCalc) ? energy : energyCalc)
                        }

                    }


                    setLevelEnergy(energy)

                } catch (e) {
                    setLoader(false)
                }


                setLevel(index)
                setNextLevelPoint(levels[index].points)
            }

            setLoader(false)
        };
        fetchData()
    }, []);


    useEffect(() => {
        updateLevelAndProgress();
    }, [totalCount]);

    useEffect(() => {
        const interval = setInterval(() => {
            setEnergy(prevEnergy => Math.min(prevEnergy + 1, levelEnergy));
        }, 500);

        return () => clearInterval(interval);
    }, [levelEnergy]);


    useEffect(() => {
        const interval = setInterval(() => {
            const date1 = dayjs(dayjs());
            const diffInSeconds = date1.diff(datetimeNow, 'second');
            // console.log(diffInSeconds, 'diffInSeconds')
            //
            // console.log(pointsEarn ,  (bonusPerHour / 3600), ' (bonusPerHour / 3600)')
            setPointsEarn(diffInSeconds * (bonusPerHour ? (bonusPerHour / 3600) : 0))
        }, 1000);

        return () => clearInterval(interval);
    }, [pointsEarn, bonusPerHour])

    const updateLevelAndProgress = () => {
        for (let i = levels.length - 1; i >= 0; i--) {
            if (totalCount >= levels[i].points) {
                setLevel(i);
                if (i < levels.length - 1) {
                    const nextLevelPoints = levels[i + 1].points;
                    setNextLevelPoint(nextLevelPoints)

                    setProgress(((totalCount - levels[i].points) / (nextLevelPoints - levels[i].points)) * 100);
                } else {
                    setProgress(100);
                }
                break;
            }
        }
    };

    const handleClick = () => {
        impactOccurred(styleHaptic)
        const pointsToAdd = level + 1;
        setEnergy(energy - (level + 1));
        setTotalCount(totalCount + pointsToAdd);


        if (energy === 1) {
            if (!videoVisibleRed) {
                setVideoVisibleRed(true);
            }
        }

        syncTapsDebounce(totalCount + pointsToAdd, InitData, energy)
        localStorage.setItem('points', String(totalCount + pointsToAdd))
        setOpacity(0);

        if (totalCount + pointsToAdd >= levels[level + 1].points) {
            setVideoVisible(true);
            setTimeout(() => {
                if (videoRef.current) {
                    videoRef.current.play();
                    notify(levels[(level + 1)].name);
                }
            }, 500);
            setTimeout(() => {
                setVideoVisible(false);
                setLevel(level + 1);
                setTotalCount(totalCount + pointsToAdd);
                setOpacity(0);
            }, 3000);
        }
    };

    const closeHandler = () => {
        document.body.style.overflow = 'auto';
        setWork(0)
    }

    if (loader) {
        return (
            <div className="preload-container">
                <img className="loader-spin" src="/loader-one.png"/>
            </div>
        )
    }

    return (
        <div className="coin-page">
            <div className="header-container" onClick={() => work !== 0 && closeHandler()}>
                <div className="header-li">
                    <strong>+{level + 1}</strong>
                    <span>Прибыль за тап</span>
                </div>
                <div className="header-li">
                    <strong>{String(nFormatter(levels[level + 1]?.points)).toUpperCase()}</strong>

                    <span>Монет для апа</span>
                </div>
                <div className="header-li">
                    <strong>+{nFormatter(bonusPerHour || 0)}</strong>
                    <span>Прибыль в час</span>
                </div>
            </div>
            <div className="coin-container">
                <div>
                    <img onClick={() => setWork(4)} className="enter-code" src="/code.svg"/>
                </div>
                <div className="coin-line">
                    <img className="coin-img" src='/coin.png' alt="coin"/>
                    <h1>{formatSpace(String(totalCount + Math.round(pointsEarn || 0) - pointsInItems))}</h1>
                </div>
                <div className={energy <= 0 ? 'coin-block pointer-none' : 'coin-block'}>
                    {videoVisible &&
                        <div className="video-footage-container" style={{opacity: opacity}}>
                            <video playsInline={true} controls={false} onPlay={() => setOpacity(1)} autoPlay={true}
                                   className="video-footage"
                                   src={`/level/${level}.mp4`} ref={videoRef}></video>
                        </div>
                    }

                    <Notcoin canIClickPlease={true}
                             sleep={energy < 1}
                             playVideo={videoVisibleRed}
                             level={level + 1}
                             funMode={false}
                             clickValue={level + 1}
                             energy={energy}
                             videoEnd={() => setVideoVisibleRed(false)}
                             cooldown={0}
                             handleClick={() => handleClick()}/>
                </div>
                <div className="boost-container">
                    <div className="boost-icon-container">
                    <img src="/boost.svg" className="boost-icon" alt="boost"/>
                    <span>{energy}/{levelEnergy}</span>
                    </div>
                    <div className="winners-btn" onClick={() => setWork(6)}>
                        <img src="/menu/4.svg" />
                    </div>
                </div>

                <div className="type-container">
                    <span>{levels[level].name}</span>
                    <span>Уровень <strong>{level + 1}/{levels.length}</strong></span>
                </div>

                <div className="Progress-container">
                    <div className="Progress-active" style={{width: `${progress.toFixed(2)}%`}}>
                    </div>
                </div>
                <div className="menu-container">
                    <div className="menu-block" onClick={() => {
                        impactOccurred(styleHapticSoft)
                        setWork(3)
                    }}><img src="/menu/1.svg" className="menu-icon"/> <span>Друзья</span></div>
                    <div className="menu-block" onClick={() => {
                        impactOccurred(styleHapticSoft)
                        setWork(1)
                    }}><img src="/menu/2.svg"  className="menu-icon"/> <span>Mine</span></div>
                    <div className="menu-block" onClick={() => {
                        impactOccurred(styleHapticSoft)
                        setWork(2)
                    }}><img src="/menu/3.svg"  className="menu-icon"/>  <span>Boost</span></div>

                    <div className="menu-block" onClick={() => {
                        impactOccurred(styleHapticSoft)
                        setWork(7)
                    }}><img src="/menu/wallet.svg"  className="menu-icon"/>  <span>Кошелек</span></div>
                </div>
                <div className="footer-light"></div>
                {work === 1 && <EarnPage totalCount={String(totalCount + Math.round(pointsEarn || 0) - pointsInItems)}
                                         save={(p, upPrice) => {
                                             setBonusPerHour(upPrice + bonusPerHour);
                                             setPointsInItems(pointsInItems + p);
                                         }}
                                         close={() => closeHandler()}/>}
                {work === 2 && <WorkProgress dancekey={2} close={() => closeHandler()}/>}
                {work === 3 && <FriendsContainer close={() => closeHandler()}/>}
                {work === 4 && <CipherPage onSuccess={(count) => {
                    setTotalCount(totalCount + count)
                    closeHandler()
                }} dancekey={1} close={() => closeHandler()}/>}
                {work === 6 && <WinnersPage  close={() => closeHandler()}/>}
                {work === 7 && <WalletPage  close={() => closeHandler()}/>}

            </div>
        </div>
    )
};

export default NotcoinPage;
