import React, {FC, useEffect, useRef, useState} from "react";
import './workProgress.css'

interface WorkProps {
    dancekey: number;
    close: () => void;
}


const WorkProgress: FC<WorkProps> = ({close, dancekey}) => {
    const [isTransitioning, setIsTransitioning] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        setIsTransitioning(true)
    });

    document.body.style.overflow = 'hidden';

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    })

    return (
        <div className={`workProgress ${isTransitioning ? 'show' : ''}`}>
            <div className="desktop-app">
                <h3>Ведутся технические работы</h3>

            </div>
            <a className="close" onClick={close}>Закрыть</a>
        </div>
    )
}

export default WorkProgress
