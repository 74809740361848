// @ts-ignore
export function getUserLevel(points, levels) {
    let userLevel = 0

    for (let i = 1; i < levels.length; i++) {
        if (points >= levels[i].points) {
            userLevel = i + 1
        } else {
            break;
        }
    }

    return userLevel;
}
