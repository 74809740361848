import React, { useState, useEffect, FC } from 'react';
import { db } from '../../firebase/init';
import { collection, query, where, getDocs, orderBy, limit, DocumentData } from 'firebase/firestore';
import 'react-toastify/dist/ReactToastify.css';
import './Index.css';
import { useInitData, useWebApp } from "@vkruglikov/react-telegram-web-app";
import {formatSpace, nFormatter} from "../../utils/formatsNum";

interface FriendProps {
    close: () => void;
}

interface Referral {
    username: string;
    points: number;
    pointsInItems: number,
    firstName: string;
}

const WinnersPage: FC<FriendProps> = ({ close }) => {
    const [referralLink, setReferralLink] = useState('');
    const [referrals, setReferrals] = useState<Referral[]>([]);
    const WebApp = useWebApp();
    const [InitDataUnsafe, InitData] = useInitData();

    const [isTransitioning, setIsTransitioning] = useState(false);

    document.body.style.overflow = 'hidden';

    useEffect(() => {
        setIsTransitioning(true);
        const generateReferralLink = () => {
            const link = `https://t.me/mellstroy_kombat_bot/start?startapp=rp_${InitDataUnsafe?.user?.id}`;
            setReferralLink(link);
        };
        generateReferralLink();
    }, [InitDataUnsafe]);

    useEffect(() => {
        const fetchReferrals = async () => {
            const q = query(collection(db, 'users'), orderBy('points', 'desc'),
                limit(100));
            const querySnapshot = await getDocs(q);

            const result: Referral[] = [];

            querySnapshot.forEach((doc) => {
                const d = doc.data();
                result.push({
                    username: d?.username,
                    points: d?.points,
                    firstName: d?.first_name,
                    pointsInItems: d.pointsInItems
                });
            });

            setReferrals(result);
        };
        fetchReferrals();
    }, [InitDataUnsafe]);

    const text = `Тапай со мной! Kликер с Мелстроем уже в телеграм! Первые три дня увеличеный бонус за приведенного друга!%0A%F0%9F%8E%81+3k монет в качестве первого подарка%0A%F0%9F%8E%81+15k монет, если у тебя есть Telegram Premium`;

    return (
        <div className={`friends-page ${isTransitioning ? 'show' : ''}`}>
            <div className="top-h1">
                <h1>🏆 Топ 100 игроков</h1>

            </div>
            <div className='friends-container'>

                <div className="referral-container">
                    {referrals.map((referral, index) => (
                        <div className={'referral-li'} key={index}>
                            <span><strong>{index + 1}.</strong> {referral.username || referral.firstName}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className="footer-friends">
                <a className="close" onClick={close}>Закрыть</a>
            </div>
        </div>
    );
};

export default WinnersPage;
