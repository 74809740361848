import { useEffect, useRef } from 'react';
import './MatrixStream'
const availableChars = "Пизда Хуй";

function getRandomChar(): string {
    return availableChars[Math.floor(Math.random() * availableChars.length)];
}

interface Node {
    code: number;
    length: number;
}

const useMatrixStream = (len: number) => {
    const status = useRef<number>(Math.floor(Math.random() * 3));
    const pipe = useRef<Node[]>([]);
    const count = useRef<number>(0);
    const renderNodes = useRef<HTMLElement[]>([]);
    const container = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (container.current) {
            for (let i = 0; i < len; i++) {
                let span = document.createElement('span');
                span.innerText = getRandomChar();
                container.current.appendChild(span);
                renderNodes.current.push(span);
            }
        }
    }, [len]);

    useEffect(() => {
        const tick = () => {
            fill();
            render();
            shift();
        };

        const fill = () => {
            while (count.current < len) {
                let node: Node = { code: status.current, length: 0 };
                switch (status.current) {
                    case 0:
                        node.length = 1;
                        break;
                    case 1:
                        node.length = 1 + Math.floor(Math.random() * len / 3 * 2);
                        break;
                    case 2:
                        node.length = 1 + Math.floor(Math.random() * len / 3);
                        break;
                    default:
                        break;
                }
                status.current = (status.current + 1) % 3;
                count.current += node.length;
                pipe.current.push(node);
            }
        };

        const render = () => {
            let idx = 0;
            for (let node of pipe.current) {
                for (let i = 0; i < node.length; i++) {
                    if (idx === len) return;
                    switch (node.code) {
                        case 0:
                            renderNodes.current[idx].innerText = getRandomChar();
                            renderNodes.current[idx].className = 'b';
                            break;
                        case 1:
                            if (Math.random() < 0.05) {
                                renderNodes.current[idx].innerText = getRandomChar();
                            }
                            renderNodes.current[idx].className = 'l';
                            break;
                        case 2:
                            renderNodes.current[idx].className = 'd';
                            break;
                        default:
                            break;
                    }
                    idx++;
                }
            }
        };

        const shift = () => {
            if (!--pipe.current[0].length) {
                pipe.current.shift();
            }
            count.current--;
        };

        const interval = setInterval(tick, 100 + Math.random() * 100);
        return () => clearInterval(interval);
    }, [len]);

    return container;
};

export default useMatrixStream;
