export function nFormatter(num: number, digits:number = 1) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const regexp = /\.0+$|(\.[0-9]*[1-9])0+$/;

    let item;
    for (let i = lookup.length - 1; i >= 0; i--) {
        if (num >= lookup[i].value) {
            item = lookup[i];
            break;
        }
    }

    return item ? (num / item.value).toFixed(digits).replace(regexp, "$1").concat(item.symbol) : "0";
}

export function formatSpace(value: string) {
    let res;
    if (value.indexOf(',') != -1) {
        res = value.replace(',', '.').replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',');
    } else {
        res = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return res;
}