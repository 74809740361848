import React, {useState, useEffect, FC} from 'react';
import './AnimateInput.css'
import {toast} from "react-toastify";
import {debounce} from "lodash";
interface WorkProps {
    inputHandler: (e: string) => void;
    loading: boolean,
}

const AnimatedInput: FC<WorkProps> =  ({inputHandler, loading}) => {
    const [text, setText] = useState('');
    const [displayText, setDisplayText] = useState('');
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index < text.length) {
            const timeoutId = setTimeout(() => {
                setDisplayText((prev) => prev + text[index]);
                setIndex(index + 1);
            }, 50);
            return () => clearTimeout(timeoutId);
        }
    }, [text, index]);

    const handleOnEmit = debounce((str) => {
        inputHandler(String(str))
    }, 1000)

    // @ts-ignore
    const handleChange = (e) => {
        let inputText = e.target.value.slice(0, 4); // Обрезаем строку до 4 символов

        if (e.target.value.length === 4) {
            handleOnEmit(inputText)
        }

        setText(inputText);
        setDisplayText('');
        setIndex(0);
    };

    return (
        <div className="animate-input">
            {!loading &&
            <input type="text" value={text} onChange={handleChange}/> }
            <div className="input-arrow">

                <img className="arrow-enter" src="/arrow.svg"/>
                {loading ?
                <div className="display-text min-display">Проверка кода...</div> :
                    <div className="display-text">{displayText}</div>}
                <div></div>
            </div>

        </div>
    );
};

export default AnimatedInput;
